import {getGlobal} from "reactn";
import {concat, findIndex, remove} from "lodash";
import {GCalendarEventFormat} from "../../actions/googleCalendar";
import {RRule, rrulestr} from 'rrule'
import moment from "moment";
import {ggTokenChecked} from "../../config/google";

export const getGoogleEvents = async (start, end) => {
    const {googleStatus, googleCalendarList, googleLibInit} = getGlobal()
    let time = moment().startOf("month").isBefore(start) ? moment().startOf("month").utc().format() : start

    let results = {}
    let nextSyncToken = {}
    if (googleStatus.is_login && googleLibInit) {
        const checked = await ggTokenChecked()
        if (!checked || !googleCalendarList.length)
            return results

        await Promise.all(googleCalendarList.map(async item => {
            try {
                const request = window.gapi.client.calendar.events.list({
                    'calendarId': item.id,
                    // "singleEvents": true,
                    "timeMin": time,
                    "timeMax": end,
                    "maxResults": 2500,
                    // "alwaysIncludeEmail":true
                })
                const {result, status} = await request.then()
                if (status !== 200)
                    return
                const isHoliday = result.accessRole === "reader" || result.accessRole === "freeBusyReader"

                const calendarItems = result.items
                const recurrenceEvents = remove(calendarItems, e => e.recurrence)
                const recurenceEdited = remove(calendarItems, e => e.recurringEventId)
                const recurring = getEventsFromReccrence(recurrenceEvents, recurenceEdited, end)
                const formatedItems = GCalendarEventFormat(concat(calendarItems, recurring), item.id, item.backgroundColor, isHoliday)

                results[item.id] = {
                    id: item.id,
                    events: formatedItems,
                    nextSyncToken: result?.nextSyncToken || null,
                    name: item.summary,
                    color: item.backgroundColor
                }
                nextSyncToken[item.id] = result?.nextSyncToken || null
            } catch (e) {
                console.log(e);
            }
        }))
    }
    return {events: results, nextSyncToken}
}

export const getEventsFromReccrence = (events, edited, endRange) => {
    const result = events.map(e => {
        try {
            if (e.recurrence && Array.isArray(e.recurrence)) {
                let idx = findIndex(e.recurrence, r => r.includes("RRULE:"))
                if (idx === -1)
                    return []
                const allDay = Boolean(e.start.date)
                const start = e.start.date || e.start.dateTime
                const end = e.end.date || e.end.dateTime
                const duration = moment(end).diff(start, allDay ? "day" : "minutes")
                const dtstart = `DTSTART:${moment(start).startOf("day").format("YYYYMMDDTHHmmss") + "Z"} `

                const rule = rrulestr(dtstart + e.recurrence[idx])
                let dataDate = []
                if (rule?.options?.until && moment(rule.options.until).isBefore(moment(start).startOf("day"))) {
                    dataDate = [moment(start).format('YYYY-MM-DD')]
                } else {
                    dataDate = rule.between(new Date(moment(start).utc().subtract(1, "day").format("YYYY-MM-DD")), new Date(moment(endRange).format("YYYY-MM-DD"))).map(date => moment(date).format("YYYY-MM-DD"))
                }
                const eventData = dataDate.map(date => {
                    if (allDay) {
                        return {
                            ...e,
                            start: {
                                date: date,
                                dateTime: null
                            },
                            end: {
                                date: moment(date).add(duration, "day").format("YYYY-MM-DD"),
                                dateTime: null
                            },
                            id: e.id + "_" + moment(date).format("YYYYMMDD"),
                            recurringEventId: e.id,
                            originStart: {
                                allDay: allDay,
                                start,
                                end
                            }
                        }
                    }
                    const startTime = moment(start).set({
                        years: moment(date).year(),
                        month: moment(date).month(),
                        date: moment(date).date()
                    }).format()
                    if (rule?.options?.until && moment(rule.options.until).isBefore(startTime)) {
                        return null
                    }
                    return {
                        ...e,
                        start: {
                            dateTime: startTime,
                            date: null
                        },
                        end: {
                            dateTime: moment(startTime).add(duration, "minutes").format(),
                            date: null
                        },
                        id: e.id + "_" + moment(date).set({
                            hours: moment(start).hours(),
                            minutes: moment(start).minutes(),
                            seconds: 0
                        }).utc().format("YYYYMMDDTHHmmss") + "Z",
                        recurringEventId: e.id,
                        originStart: {
                            allDay: allDay,
                            start,
                            end
                        },
                        editable: !e.isEventVote,
                        droppable: !e.isEventVote
                    }
                })
                edited.forEach(evt => {
                    let idx = findIndex(eventData, {id: evt.id})
                    if (idx !== -1) {
                        if (evt.status === "canceled") {
                            eventData.splice(idx, 1)
                        } else {
                            eventData[idx] = {
                                ...evt,
                                recurrence: e.recurrence,
                                originStart: {
                                    allDay: allDay,
                                    start,
                                    end
                                }
                            }
                        }
                    }
                })
                // console.log({eventData});
                return eventData
            } else {
                return []
            }
        } catch (error) {
            console.log(error, e);
            return []
        }

    })
    return result.flat()

}
export const getGeniamEventsFromReccrence = (events, edited = [], endRange) => {
    const result = events.map(e => {
        try {
            if (e.recurrence) {
                const allDay = Boolean(e.allDay)
                const start = e.start
                const end = e.end
                const endRecurrence = moment(e.start).add(1, "year").format()
                const duration = moment(end).diff(start, allDay ? "day" : "hour")
                // const dtstart = allDay ? `${moment(start).startOf("day").format("YYYYMMDDTHHmmss") + "Z"}\n` : `${moment(start).startOf("day").utc().format("YYYYMMDDTHHmmss") + "Z"}\n`
                const options = RRule.parseString(e.recurrence)
                options.dtstart = new Date(moment(e.start).startOf("day").format())
                const rule = new RRule({
                    ...options,
                    tzid: 'Asia/Tokyo'
                })
                const dataDate = rule.between(new Date(moment(start).utc().subtract(1, "day").format("YYYY-MM-DD")), new Date(moment(endRecurrence).format("YYYY-MM-DD"))).map(date => moment(date).format("YYYY-MM-DD"))
                // console.log({dataDate});
                const eventData = dataDate.map(date => {
                    if (allDay) {
                        return {
                            ...e,
                            start: date,
                            end: moment(date).add(duration, "day").format("YYYY-MM-DD"),
                            id: e.id + "_" + moment(date).format("YYYYMMDD"),
                            recurringEventId: e.id,
                            originStart: {
                                allDay: allDay,
                                start,
                                end
                            }
                        }
                    }
                    const startTime = moment(start).utc().set({
                        years: moment(date).year(),
                        month: moment(date).month(),
                        date: moment(date).date()
                    }).format()
                    return {
                        ...e,
                        start: startTime,
                        end: moment(startTime).add(duration, "hours").format(),
                        id: e.id + "_" + moment(date).set({
                            hours: moment(start).hours(),
                            minutes: moment(start).minutes(),
                            seconds: 0
                        }).utc().format("YYYYMMDDTHHmmss") + "Z",
                        recurringEventId: e.id,
                        originStart: {
                            allDay: allDay,
                            start,
                            end
                        },
                        editable: !e.isEventVote,
                        droppable: !e.isEventVote
                    }
                })
                edited.forEach(evt => {
                    let idx = findIndex(eventData, {id: evt.id})
                    if (idx !== -1) {
                        if (evt.status === "canceled") {
                            eventData.splice(idx, 1)
                        } else {
                            eventData[idx] = {
                                ...evt,
                                recurrence: e.recurrence,
                                originStart: {
                                    allDay: allDay,
                                    start,
                                    end
                                }
                            }
                        }
                    }
                })
                // console.log({eventData});
                return eventData
            } else {
                return []
            }
        } catch (e) {
            return []
        }
    })
    return result.flat()

}

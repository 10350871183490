import {isEmpty} from 'lodash/lang';

export const customAlerts = (
    metamorNotExits = 'The metamor no longer exists', router = '/', isLogin = false, cartLink = null, redirect = true) => {
    alert(metamorNotExits);
    if (isLogin) {
        return window.location.assign(
            process.env.REACT_APP_MYACCOUNT + '/login?redirect_url=' +
            encodeURIComponent(window.location.href));
    }
    if (!isEmpty((cartLink))) {
        if (redirect)
            return window.location.assign(
                cartLink + '&redirect_url=' +
                encodeURIComponent(window.location.href));
        else
            return window.location.assign(cartLink)
    }
    return window.location.assign(router);
};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import {MediaQueryProvider} from "./context/mediaQuery";
import "./config/firebaseConfig"
import axiosConfig from './config/axios'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UserProvider} from "./context/UserContext";
import "./config/global"
import GoogleAuth from "./config/google";
import {ThemeProviderContext} from "./context/ThemeContext";

GoogleAuth()
axiosConfig()

ReactDOM.render(
    <>
        <BrowserRouter>
            <MediaQueryProvider>
                <ThemeProviderContext>
                    <UserProvider>
                        <App/>
                        <ToastContainer
                            hideProgressBar={true}
                            closeOnClick={false}
                            autoClose={2000}
                            position="bottom-center"
                        />
                    </UserProvider>
                </ThemeProviderContext>
            </MediaQueryProvider>
        </BrowserRouter>
    </>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

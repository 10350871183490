import React, {createContext, useEffect} from 'react';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import {auth, db} from '../config/firebaseConfig';
import {CALENDAR_COLLECTION, IS_PRODUCTION} from '../config/constants';
import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, setCookie} from '../common/cookies';
import qs from 'query-string';
import {getUserData, signInFirebase} from '../common/token/user';
import {useHistory} from 'react-router-dom';
import {useMyUserData} from './common/users';
import {GCalendarList} from '../actions/googleCalendar';
import store from "store";
import {useDocumentData} from 'react-firebase-hooks/firestore';

export const UserContext = createContext({})

export const UserProvider = ({children}) => {
    const [user] = useGlobal('user');
    const [, setCalendarUser] = useGlobal('calendarUser');
    const [googleLibInit] = useGlobal('googleLibInit');
    const [, setGGStatus] = useGlobal('googleStatus');
    const [calendar, loading] = useDocumentData(db.doc(CALENDAR_COLLECTION + `/${user.user_id}`))
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();
    // let loaded = false;

    useMyUserData()

    useEffect(() => {
        const {
            token,
            refreshToken
        } = query;

        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (IS_PRODUCTION)
                opts.domain = '.geniam.com'
            setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refreshToken, opts);
            setGlobal({
                accessToken: token,
                refreshToken
            }, () => {
                getUserData().then(data => {
                        if (!data)
                            setGlobal({langDefault: "ja-jp"})
                    }
                ).catch(e => {
                    console.log(e);
                    setGlobal({langDefault: "ja-jp"})
                });
            });
            // localStorage.setItem('refreshToken', refreshToken)

            delete query.token;
            delete query.refreshToken;
            history.push(`${path}?${qs.stringify(query)}`)
        } else {
            getUserData().then(data => {
                    if (!data)
                        setGlobal({langDefault: "ja-jp"})
                }
            ).catch(e => {
                console.log(e);
                setGlobal({langDefault: "ja-jp"})
            });
        }

        auth.onAuthStateChanged(user => {
            if (!user) {
                signInFirebase()
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!user?.user_id || !googleLibInit || (!calendar && !loading)) return;
        // if (!IS_PRODUCTION) console.log('user', user?.user_id)
        // console.log('sub useCalendarUser', user.user_id)

        const data = {...calendar}

        setCalendarUser(data)
        // check user is sync with google
        const {googleStatus} = getGlobal()
        if (data.googleAuth) {
            store.set("googleAuth", data.googleAuth)
            if ((!googleStatus.is_login || googleStatus.userProfile?.email !== data.googleAuth.email)) {
                googleStatus.is_login = true
                googleStatus.userProfile = data.googleAuth
                setGGStatus({...googleStatus}, () => {
                    GCalendarList()
                })
            }
            // loaded = true
        } else {
            setGlobal({
                googleStatus: {
                    is_login: false,
                    userProfile: {},
                    initialed: true
                },
                googleCalendarList: [],
                ggEventLoaded: true
            }, () => {
                // refetchSource('google')
            })
        }
        // eslint-disable-next-line
    }, [user?.user_id, googleLibInit, calendar, loading]);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    )
}

import React, {createContext, useContext, useEffect, useState} from 'react'
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import moment from "moment";
import {getGeniamEventsFromReccrence, getGoogleEvents} from "./common/getGoogleEvents";
import {concat, filter, findIndex, groupBy, remove} from 'lodash'
import {getGeniamEvents} from "./common/getGeniamEvents";
import {getGeniamProjects} from "./common/getProjects";
import {toast} from "react-toastify";
import {swapProjectByOrder} from "../components/MyAccount/EditFreeTime/GroupCalendar/common";

export const CalendarEventsContext = createContext({})

export const useCalendarEvents = () => {
    return useContext(CalendarEventsContext)
}

export const CalendarEventsProvider = ({children}) => {
    const value = useEvents()
    return (
        <CalendarEventsContext.Provider value={value}>
            {children}
        </CalendarEventsContext.Provider>
    )
}

let refetchTimeout = null
// let updated = Date.now()

const useEvents = () => {
    const [refetchDateNow] = useGlobal("refetchDateNow")
    const [calendarTab1] = useGlobal("calendarTab1")
    const [user] = useGlobal("user")
    const [projects, setProjects] = useGlobal("projects")
    const [googleCalendarList] = useGlobal("googleCalendarList")
    const [geEvents, setGeEvents] = useGlobal("geEvents")
    const [ggEvents, setGGEvents] = useGlobal("ggEvents")
    const [, setNextSyncToken] = useGlobal("nextSyncTokens")
    const [calendarUser] = useGlobal("calendarUser")
    const [googleList, setList] = useState([])

    useEffect(() => {
        const googleSettings = calendarUser?.googleSettings || {};
        const googleList = calendarUser?.googleList || [];
        const googleAllList = filter(googleCalendarList, g => !g.id.includes("#holiday")).map(gg => {
            const linkInfo = googleSettings[gg.id] || {};
            return {
                ...gg, ...linkInfo,
                is_google: true,
                uuid: gg.id,
                color: gg.backgroundColor,
                name: gg.summary,
                selected: gg.selected,
            }
        })
        const geniamAllList = filter(projects, p => !p.is_sync)
        const nextAllList = swapProjectByOrder(concat(geniamAllList, googleAllList), googleList);
        setGlobal({
            allProjectList: nextAllList
        })
        // eslint-disable-next-line
    }, [projects, googleCalendarList, calendarUser])

    useEffect(() => {
        if (isListChange(googleList, googleCalendarList)) {
            setList([...googleCalendarList])
        }
        // eslint-disable-next-line
    }, [googleCalendarList])

    useEffect(() => {
        if (refetchTimeout) {
            clearTimeout(refetchTimeout)
        }
        refetchTimeout = setTimeout(() => {
            reLoadEvents()
        }, 300)
        // reLoadEvents()
        // eslint-disable-next-line
    }, [refetchDateNow, calendarTab1, googleList])

    const reLoadEvents = async () => {
        const {start, end} = getDateRange(refetchDateNow)

        if (!googleCalendarList.length) {
            setGGEvents({})
            return
        }
        try {
            const {events, nextSyncToken} = await getGoogleEvents(start, end)
            setGlobal({
                ggEventLoaded: true
            })
            if (!events)
                return
            setNextSyncToken(nextSyncToken)
            setGGEvents(events)
        } catch (e) {
            console.log(e);
            toast.error("load event google error")
            setGlobal({
                ggEventLoaded: true
            })
        }
    }

    useEffect(() => {
        // if (!projects.length)
        //     return
        if (!user?.user_id)
            return null
        const sub = getGeniamEvents((snapshot) => {
            setGlobal({
                geEventLoaded: true
            })

            const {end} = getDateRange(refetchDateNow)
            let data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
            data = groupBy(data, "project_uuid")
            let result = {}
            Object.keys(data).forEach(key => {
                let value = data[key]
                // let idx = findIndex(projects, {id: key})
                // if (idx !== -1)
                const recurrence = remove(value, item => item.recurrence)
                // console.log({recurrence, value});
                const recurenceEdited = remove(value, e => e.recurringEventId)
                // console.log({recurenceEdited});
                const recurring = getGeniamEventsFromReccrence(recurrence, recurenceEdited, end)
                // console.log({recurring});
                result[key] = {
                    events: concat(value, recurring),
                    id: key,
                    // name: projects[idx].name
                }
            })
            setGeEvents(result)
        })

        return () => {
            if (sub)
                sub()
        }
        // eslint-disable-next-line
    }, [user?.user_id])

    useEffect(() => {
        if (!user?.user_id)
            return
        const sub = getGeniamProjects(user.user_id, (err, snap) => {
            if (err)
                return
            let data = snap.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                is_google: false
            }));
            const {calendarUser} = getGlobal();
            const {googleList = []} = calendarUser;
            if (googleList.length) {
                data = swapProjectByOrder(data, googleList)
            }
            setProjects(data, () => {
                // refetchSource('geniam')
            })
        });
        return () => {
            if (sub)
                sub()
        }
        // eslint-disable-next-line
    }, [user?.user_id]);

    const isListChange = (prevList, nextList) => {
        if (prevList.length !== nextList.length)
            return true
        let result = false
        prevList.forEach((oldvalue) => {
            let idx = findIndex(nextList, {id: oldvalue.id})
            if (idx === -1) {
                result = true
            }
        })
        return result
    }

    const refresh = () => {
        setGeEvents({...geEvents})
    }

    const createEvent = (event) => {
        const {ggEvents, geEvents} = getGlobal()
        const {project_uuid, is_google, googleEvent} = event
        if (is_google || googleEvent) {
            // updated = Date.now()
            let idx = findIndex(googleCalendarList, {id: project_uuid})
            if (idx === -1)
                return;
            if (ggEvents[project_uuid]) {
                ggEvents[project_uuid].events.push(event)
            } else {
                ggEvents[project_uuid] = {
                    id: project_uuid,
                    events: [event],
                    name: googleCalendarList[idx].summary,
                    color: googleCalendarList[idx].backgroundColor
                }
            }
            setGlobal({
                    ggEvents: {...ggEvents}
                },
                () => {
                    console.log({ggEvents})
                }
            )
            return
        }
        if (geEvents[project_uuid]) {
            geEvents[project_uuid].events.push(event)
        } else {
            geEvents[project_uuid] = {
                id: project_uuid,
                events: [event]
            }
        }
        setGlobal({geEvents: {...geEvents}}, () => {
            console.log({geEvents})
        })

    }
    const deleteEvent = (event, callback) => {
        const {ggEvents, geEvents, voteEvents} = getGlobal()
        const {is_google, id, googleEvent} = event
        if (!event.project_uuid) {
            remove(voteEvents, {id})
            setGlobal({
                voteEvents: [...voteEvents]
            }, () => {
                // refetchSource('vote')
            })
            return;
        }
        if (is_google || googleEvent) {
            // updated = Date.now()
            Object.keys(ggEvents).forEach(key => {
                if (ggEvents[key]?.events?.length) {
                    remove(ggEvents[key].events, {id: id})

                }
            })
            setGlobal({
                ggEvents: {...ggEvents}
            }, () => {
                if (callback && typeof callback === "function") {
                    callback(event)
                }
            })
            return
        }
        Object.keys(geEvents).forEach(key => {
            if (geEvents[key]?.events?.length) {
                remove(geEvents[key].events, {id: id})

            }
        })
        setGlobal({geEvents: {...geEvents}}, () => {
            if (callback && typeof callback === "function") {
                callback(event)
            }
        })
    }
    const updatedEvent = (event, oldEvent) => {
        const {project_uuid, is_google, id, googleEvent} = event
        if (!event || !oldEvent)
            return;
        if (oldEvent.project_uuid !== project_uuid) {
            console.log('move calendar')
            deleteEvent(oldEvent, () => {
                createEvent(event)
            })
            return
        }
        if ((is_google || googleEvent) && ggEvents[project_uuid]?.events?.length) {
            // updated = Date.now()
            let idx = findIndex(ggEvents[project_uuid].events, {id})
            if (idx !== -1) {
                ggEvents[project_uuid].events[idx] = event
                setGGEvents({...ggEvents})
            }
            return;
        }
        if (geEvents[project_uuid]?.events?.length) {
            let idx = findIndex(geEvents[project_uuid].events, {id})
            if (idx !== -1) {
                geEvents[project_uuid].events[idx] = event
                setGeEvents({...geEvents})
            }
        }

    }
    const changeTime = (event) => {
        const {ggEvents, geEvents} = getGlobal()
        const {project_uuid, is_google, id, googleEvent} = event
        if (is_google || googleEvent) {
            // updated = Date.now()
            let idx = findIndex(ggEvents[project_uuid]?.events || [], {id})
            if (idx !== -1) {
                ggEvents[project_uuid].events[idx].start = event.start
                ggEvents[project_uuid].events[idx].end = event.end
                ggEvents[project_uuid].events[idx].allDay = event.allDay
                setGlobal({
                    ggEvents: {...ggEvents}
                })
            }
            return
        }
        if (geEvents[project_uuid]?.events?.length) {
            let idx = findIndex(geEvents[project_uuid].events, {id})
            if (idx !== -1) {
                geEvents[project_uuid].events[idx].start = event.start
                geEvents[project_uuid].events[idx].end = event.end
                geEvents[project_uuid].events[idx].allDay = event.allDay
                setGlobal({
                    geEvents: {...geEvents}
                })
            }
        }

    }
    const revertEvent = (event, oldEvent) => {
        deleteEvent(event)
        createEvent(oldEvent)
    }

    return {
        geEvents,
        ggEvents,
        createEvent,
        deleteEvent,
        updatedEvent,
        revertEvent,
        changeTime,
        refresh,
        reLoadEvents
    }
}

export const getDateRange = (date, num = 0) => {
    const type = 'month'
    const start = moment(date).startOf(type).subtract(1, type).utc().format()
    const end = moment(start).add(num + 2, type).utc().format()
    return {
        start,
        end
    }
}
//
// const getType = () => {
//     const {view} = getGlobal()
//     let type
//     switch (view) {
//         case "CustomWeek":
//             type = "week"
//             break
//         case "CustomDay":
//             type = "day"
//             break
//         default:
//             type = "month"
//     }
//     return type
// }

export const IS_PRODUCTION = process.env.REACT_APP_ENV === "production"
export const CALENDAR_COLLECTION = IS_PRODUCTION ? "calendar" : "calendar-stg"
export const METAMORS_COLLECTION = IS_PRODUCTION ? "metamors" : "metamors-stg"
export const MEETING_COLLECTION = IS_PRODUCTION ? "meetings" : "meetings-stg"
export const API_GENIAM = IS_PRODUCTION ? "https://api.geniam.com" : "https://api-stg.geniam.com"
export const GET_USER_INFO = API_GENIAM + "/getuserdata"
export const LOGIN_URL = IS_PRODUCTION ? "https://myaccount.geniam.com/login" : "https://myaccount-stg.geniam.com/login"
export const ACCOUNT_URL = IS_PRODUCTION ? "https://myaccount.geniam.com/" : "https://myaccount-stg.geniam.com/"
export const STRIPE_CLIENT = IS_PRODUCTION ? "ca_FkRcNEjFUQYVl9CiFjPVT5NS3wZhXwIo" : "ca_FkRcdm2FCs2NPvi9R8LPkNKmTWmG69HS"
export const METAMORS_COLL = IS_PRODUCTION ? "metamors" : "metamors-stg"
export const QUESTUM_LIVE = IS_PRODUCTION ? "questumLive" : "questumLive-stg"
export const IDENTITIES_COLL = IS_PRODUCTION ? "identities" : "identities-stg"
export const QUESTUM_APP =
    window.location.hostname === 'localhost'
        ? `${window.location.protocol}//localhost:3300` // cordemia port 3300
        : IS_PRODUCTION
            ? "https://cordemia.geniam.com"
            : "https://cordemia-stg.geniam.com"
export const USER_COLLECTION = IS_PRODUCTION ? "users" : "users-stg"

import React, {lazy, Suspense, useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import './globals.css';
import './App.css';
import {LanguageMessageProvider} from './context/LanguageMessageContext';
import {CalendarEventsProvider} from './context/CalendarEventsContext';
import ClfLinearProgress from './components/Custom/CLFLinearProgress';
import {IS_PRODUCTION} from './config/constants';

const pages = {
    Home: lazy(() => import('./components/Home')),
    MyAccount: lazy(() => import('./components/MyAccount')),
    MyCalendar: lazy(() => import('./components/MyAccount/MyCalendar')),
    Connects: lazy(() => import('./components/MyAccount/StripeConnect')),
    QuestumLive: lazy(() => import('./components/CordemiaEvents')),
    PageNotFound: lazy(() => import('./components/PageNotFound/PageNotFound')),
}

function App() {
    const history = useHistory()

    useEffect(() => {
        if (window.location.pathname === "/") {
            history.push("/myaccount")
        }
        if (!IS_PRODUCTION) {
            document.title = `[${process.env.REACT_APP_ENV}] ${document.title}`
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="App">
            <Switch>
                <Suspense fallback={<ClfLinearProgress/>}>
                    <Route exact path={"/myaccount"} component={pages.MyAccount}/>
                    <Route exact path={"/"} component={pages.MyAccount}/>
                    <LanguageMessageProvider>
                        <CalendarEventsProvider>
                            {/*<Route exact path={"/myaccount"} component={pages.MyAccount}/>*/}
                            <Route exact path={"/mycalendar"} component={pages.MyCalendar}/>
                            <Route exact path={"/stripeConnects"} component={pages.Connects}/>
                            <Route exact path={"/events"} component={pages.QuestumLive}/>
                            <Route component={pages.PageNotFound}/>
                        </CalendarEventsProvider>
                    </LanguageMessageProvider>
                </Suspense>
            </Switch>
        </div>
    );
}

export default App;

import React, {useEffect, useState} from 'react';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import '../index.css';
import '../globals.css';
import 'antd/dist/antd.css';
import "@Andom-Geniam/git-globalnavi/dist/index.css";
import ClfLinearProgress from '../components/Custom/CLFLinearProgress';

export const UseThemeProviderContext = React.createContext({});

export const ThemeProviderContext = ({children}) => {
    const theme = useThemeContext();
    if (!theme)
        return <ClfLinearProgress/>;
    return (
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
    )
}

export const useThemeContext = () => {
    const [colors] = useGlobal('colors');
    const [theme, setTheme] = useState(createTheme({}))

    useEffect(() => {
        if (!colors?.primary || !colors)
            return;

        setTheme(createTheme({
            props: {
                primaryColor: colors.primary,
                secondaryColor: "#690003",
                // or add more props to handle theme
                MuiButtonBase: {
                    // The properties to apply
                    // disableRipple: true, // No more ripple, on the whole application 💣!
                },
            },
            transitions: {
                // So we have `transition: none;` everywhere
                create: () => 'none',
            }
        }))
    }, [colors]);

    return theme
}


export const handleRevertColors = () => {
    const {calendarUser} = getGlobal()
    let {colors = {}} = calendarUser
    if (colors?.primary) {
        setGlobal({
            colors: colors
        })
    } else {
        setGlobal({
            colors: {primary: '#1790FF', secondary: '#0029FF'}
        })
    }
}

import axios from 'axios'
import {getGlobal, setGlobal} from 'reactn'
import {API_GENIAM, GET_USER_INFO, LOGIN_URL} from "../../config/constants";
import Cookies from 'js-cookie'
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie,} from "../cookies";
import {auth} from "../../config/firebaseConfig";

const isIgnorePath = () => {
    return ["/", "/insight-map"].includes(window.location.pathname)
}
/**
 *
 * @returns {Promise<null|any>}
 */
export const getUserData = async () => {
    const {accessToken, firebaseToken} = getGlobal();
    localStorage.removeItem('userInfo');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('zoomConnectData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('firebaseToken');

    if (!accessToken || !firebaseToken) await refreshToken()

    try {
        if (isIgnorePath())
            return null;
        const {data} = await axios.get(GET_USER_INFO)
        setGlobal({user: data});
        localStorage.setItem('userInfo', JSON.stringify(data))
        return data
    } catch (e) {
        console.log(e)
        localStorage.clear()
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
        if (!isIgnorePath())
            window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null
    }
}

/**
 *
 * @returns {Promise<null|any>}
 */
export const refreshToken = async () => {
    const refreshToken = Cookies.get(COOKIE_GENIAM_REFRESH_TOKEN_KEY) || getGlobal().refreshToken

    // const query = qs.parse(window.location.search)
    // const path = window.location.pathname
    // if (!refreshToken && path === "/scheduleAdjustment")
    //     return null
    // if (!refreshToken && path === '/event' && query.id && query.from && query.tokenMeeting)
    //     return null
    //
    // if (!refreshToken && path === '/eventAnonymous' && query.id && query.from)
    //     return null

    if (!refreshToken) {
        if (!isIgnorePath())
            window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null;
    }

    try {
        const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
        const data = res.data;
        setGlobal({
            accessToken: data.accessToken,
            firebaseToken: data.firebaseToken
        });
        return data;
    } catch (e) {
        // console.log(e)
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
        if (!isIgnorePath())
            window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null;
    }
}
/**
 *
 * @returns {Promise<void>}
 */
export const signInFirebase = async () => {
    try {
        const data = await refreshToken()
        await auth.signInWithCustomToken(data.firebaseToken)
    } catch (e) {
        console.log(e);
    }
}

import {db} from "../config/firebaseConfig";
import {METAMORS_COLLECTION, USER_COLLECTION} from "../config/constants";
import {getGlobal} from 'reactn';
import {get} from 'lodash';

export const myBusyEventsColl = (uid) => {
    return db.collection(METAMORS_COLLECTION).doc(uid).collection('BusyColl')
}

export const myMetamorRef = (uid) => {
    return db.collection(METAMORS_COLLECTION).doc(uid)
}

export const myFreeTimeRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${METAMORS_COLLECTION}/${user_id}/freeTime/MyFreeTime`)
}

export const myUserDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${USER_COLLECTION}/${user_id}`)
}

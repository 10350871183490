import {setGlobal, useGlobal} from 'reactn';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {myMetamorRef, myUserDocRef} from '../../common/FirebaseRef';
import {useEffect} from 'react';
import moment from 'moment';
import {useAtom} from 'jotai';
import {metamorInfo} from '../../atoms/metamor.atom';
import {customAlerts} from '../../common/alert';

export const useMyUserData = () => {
    const [user] = useGlobal('user')
    const [, setUserInfo] = useGlobal("userInfo");
    const [myUser] = useDocumentData(user?.user_id ? myUserDocRef() : null)

    useEffect(() => {
        if (!myUser)
            return;
        setUserInfo({...myUser})
        setGlobal({
            targets: dataDefault().targetPersons,
            contents: dataDefault().contents
        })
        // eslint-disable-next-line
    }, [myUser])
}

export const useMetamorData = () => {
    const [user] = useGlobal('user')
    const [metamor, loading, error] = useDocumentData(user?.user_id ? myMetamorRef(user.user_id) : null)
    const [, setMetamor] = useAtom(metamorInfo)

    useEffect(() => {
        if (loading || error)
            return;
        if ((!metamor && !loading) || (metamor && !metamor?.isMetamor))
            return customAlerts("メタマーではありません。このページを閉じてください。", "/myaccount");

        setMetamor(metamor)
        setGlobal({
            vimeo: metamor?.vimeo || null
        })
        // eslint-disable-next-line
    }, [metamor])
}


export const dataDefault = () => {

    const targetPersons = [
        {
            id: "tp_6qiP060nmktxe",
            name: "経営者支援",
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "tp_6qiPMp4OPBSJB",
            name: "リーダー支援",
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "tp_6qiPMp4Rb4S4a",
            name: "ビジネス支援",
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "tp_7Xijv9YjMJI6a",
            name: "キャリア支援",
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "tp_4Xijv9ZjNJI5b",
            name: "ライフ支援",
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
    ]
    const contents = [
        {
            id: "ctn_6qiP060nmktxe",
            name: 'エネカラー',
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "ctn_6qiPMp4OPBSJB",
            name: 'ユニゾン・リスニング',
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "ctn_6qiPMp4Rb4S4a",
            name: 'アイミング',
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "ctn_7Xijv9YjMJI6a",
            name: 'インサイトマップ',
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
        {
            id: "ctn_4Xijv9ZjNJI5b",
            name: '&理論',
            createdAt: moment().format(),
            updatedAt: moment().format()
        },
    ]

    return {targetPersons, contents}
}

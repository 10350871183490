import {addCallback, setGlobal} from 'reactn'
import store from "store";
// import {IS_PRODUCTION} from "./constants";
import {isMobileOnly} from "react-device-detect";
import moment from "moment";

addCallback(globalState => {
    // if (!IS_PRODUCTION)
    //     console.log({globalState})
})

const calendarTab2 = store.get('calendarTab2')
const calendarTab1 = store.get('calendarTab1')
const fontSize = store.get('fontSize') || 9
const view = isMobileOnly ? 'CustomWeek' : store.get('view')
const fullHeight = store.get('fullHeight') || 'fullHeight'
const localEdit = store.get("editFreeBox") || []

setGlobal({
    user: {},
    googleLibInit: false,
    googleStatus: {
        is_login: Boolean(store.get("googleAuth")),
        userProfile: store.get("googleAuth") || {},
        initialed: Boolean(store.get("googleAuth"))
    },
    metamor: {},
    meetingTimes: {
        minTime: {
            value: 6,
            label: '06:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },
    calendarTab2: calendarTab2 ? calendarTab2 : 2,
    calendarTab1: calendarTab1 ? calendarTab1 : 2,
    // refetchDateNow: refetchDateNow ? refetchDateNow : Date.now(),
    refetchDateNow: moment().format('YYYY-MM-DD'),
    groups: [],
    view: view ? view : 'CustomMonth',
    tab: 1,
    tabLink: 0,
    tabForm: 0,
    googleCalendarList: [],
    times: {
        minTime: {
            value: 7,
            label: '07:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },
    anchor: {
        top: 0,
        left: 0
    },
    GoogleColor: [
        "#7986CB",
        "#33B679",
        "#8E24AA",
        "#E67C73",
        "#F6BF26",
        "#F4511E",
        "#039BE5",
        "#616161",
        "#3F51B5",
        "#0B8043",
        "#D50000"
    ],
    syncToken: [],
    syncEvents: [],
    GChannel: localStorage.getItem('GChannel') ? JSON.parse(localStorage.getItem('GChannel')) : [],
    LChannel: localStorage.getItem('LChannel') ? JSON.parse(localStorage.getItem('LChannel')) : {},
    hoverEvent: null,
    hoverTitle: {
        title: '',
        start: '',
        end: ''
    },
    emojis: [
        {
            name: 'AssignmentTurnedIn',
            emoji: "✅"
        },
        {
            name: 'Group',
            emoji: "👥"
        },
        {
            name: 'AccountCircle',
            emoji: "👤"
        },
        {
            name: 'AssignmentInd',
            emoji: "🔄"
        },
    ],
    openSelectCalendar: false,
    calendarSelected: localStorage.getItem('calendarSelected') ? JSON.parse(localStorage.getItem('calendarSelected')) : [],
    allProject: [],
    fontSize: fontSize,
    hiddenDays: [],
    apiCalendar: null,
    language: store.get("language") || "ja-jp",
    switchEmail: null,
    editFreeSlot: null,
    notifications: [],
    myFreeTime: null,
    dateSelections: [],
    hoverVote: null,
    hoverPos: null,
    hoverDay: null,
    geEvents: {},
    ggEvents: {},
    settingTab: 0,
    calendarUser: {},
    clickInfo: null,
    nextSyncTokens: {},
    zoomOAuth: {},
    zoomMeetings: [],
    accessToken: null,
    firebaseToken: null,
    refreshToken: null,
    prevAction: [],
    colors: JSON.parse(localStorage.getItem('colors')) || {primary: '#1790FF', secondary: '#690003'},
    fullHeight: fullHeight ? fullHeight : '',
    editFreeTime: localEdit.length !== 0,
    dateBusy: [],
    allProjectList: [],
    userInfo: {},
    targets: [],
    contents: [],
    lives: [],
    past: [],
    vimeo: null
})

if (process.env.REACT_APP_ENV === "production") {
    console.log = () => {
    }
}

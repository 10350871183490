import axios from "axios";
import {refreshToken} from "../common/token/user";
import {includes} from 'lodash'
import {toast} from "react-toastify";
import React from "react";
import localToken from '../common/token/localToken'
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie} from "../common/cookies";
import {getGlobal} from 'reactn';

export default () => {
    //REQUEST
    axios.interceptors.request.use(
        (config) => {
            // const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (includes(config.url, "https://api.vimeo.com")) {
                const {vimeo} = getGlobal()
                if (vimeo?.access_token) {
                    config.headers['Authorization'] = `Bearer ${vimeo.access_token}`;
                }
                return config
            }
            if (includes(config.url, "https://asia-northeast1-geniam-c8d4c.cloudfunctions.net"))
                return config
            const {token} = localToken();
            if (token) {
                if (includes(config.url, 'https://www.googleapis.com')) {
                    // if (!includes(config.url, CALENDAR_API_KEY)) {
                    //     const googleAccessToken = localStorage.getItem('googleAccessToken')
                    //     config.headers['Authorization'] = `Bearer ${googleAccessToken}`;
                    // }
                } else {
                    config.headers['Authorization'] = `Bearer ${token}`;
                    // config.headers['Strict-Transport-Security'] = `max-age=31556926 ; includeSubDomains`;
                    // config.headers['X-Frame-Options'] = `SAMEORIGIN`;
                    // config.headers['X-Content-Type-Options'] = `nosniff`;
                    // config.headers['X-Permitted-Cross-Domain-Policies'] = `none`;
                    // config.headers['X-XSS-Protection'] = `1; mode=block`;
                }
            }
            return config;
        },
        error => {
            if (error && error.request) {
                console.log("error 0", error);
                console.log(error.request);
                toast.error(<ToastFormat error={error}/>)
            }
            return Promise.reject(error);
        });

    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            // const query = qs.parse(window.location.search);
            if (!error.response) {
                toast.error(<ToastFormat error={error}/>)
                return Promise.reject(error)
            }

            console.log("error 1", error);
            if (
                (error.response.status === 400 && error.response.data.error_code === 'NotAuthorizedException')
                || (error.response.status === 401 && error.response.data.error === 'login required') || error.response.status === 440
            ) {
                if (
                    error.response.data.error_message === 'Invalid Refresh Token'
                    || error.response.data.error_message === 'Refresh Token has been revoked'
                    || error.response.data.error_message === 'Refresh Token has expired'
                ) {
                    removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
                    removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
                    // window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
                    return Promise.reject(error)
                }
                const data = await refreshToken()
                if (data) {
                    originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
                    return axios(originalRequest)
                } else {
                    return Promise.reject(error)
                }

            }
            return Promise.reject(error);
        }
    );
}

function ToastFormat({error}) {
    const {url} = error.config
    const msg = error.toString()
    return (
        <div>
            <div>
                {url}
            </div>
            <div>{msg}</div>
        </div>
    )
}
